export const BACKEND_ENDPOINTS = {
  ECOM_PRODUCTS: "/events/products?page=1",
  PRODUCT: "/events/product/",
  CALCULATED_DATE_RETURN: `${process?.env?.BACKEND_URL}box/calculated-date-return`,
  CREATE_BOX: `${process?.env?.BACKEND_URL}box/create-box`,
};
export const FRONTEND_ENDPOINTS = {
  HOME: "/",
  TAMARA: process?.env?.ECOM_SHOP_LINK + "tamara",
  PASSWORD_RESET_SUCCESS: "/login?toast=true",
  SURVEY_ENDPOINT: "/survey",
  CHECKOUT_ENDPOINT: "/checkout",
  EMAIL_VERIFICATION_ENDPOINT: "/survey-complete",
  ORDER_A_BOX_ENDPOINT: "/dashboard/orderABox",
  SA_PAYMENT_SUCCESS: "/checkout/sa-payment-success",
};
